import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Alert, Button, Typography } from 'amphitheatre';

import Layout from 'src/Layout';

import Container from 'src/Components/ContentContainer';
import ToTop from 'src/Components/ToTop';

import * as Base from 'src/page-styles/base.styled';

import Step1Image from 'src/assets/images/tutorials/step3/pic1.png';
import Step2Image from 'src/assets/images/tutorials/step3/pic2.png';

import Step1EditImage from 'src/assets/images/tutorials/step3/pic3.png';
import Step2EditImage from 'src/assets/images/tutorials/step3/pic4.png';
import Step3EditImage from 'src/assets/images/tutorials/step3/pic5.png';
import Step4EditImage from 'src/assets/images/tutorials/step3/pic6.png';

const { Header, Paragraph } = Typography;

const BtnGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

function Step1(): JSX.Element {
  return (
    <Layout>
      <ToTop />
      <Container.Dark>
        <Base.MenuGap />
        <Base.Heading>Tutorial 3: Ground stations</Base.Heading>
        <Base.Text>
          <Header level={3}>Step 1</Header>
          <Base.Image src={Step1Image} />
          <Paragraph>
            Click the ‘Satcom’ button on the P.I.G.I. menu wheel.
          </Paragraph>
          <Base.Gap />
          <Header level={3}>Step 2</Header>
          <Base.Image src={Step2Image} />
          <Paragraph>
            This should bring up the ‘Add Ground Station’ module onto the
            screen.
          </Paragraph>
          <Paragraph>
            Change the parameters to your liking and name your ground station.
          </Paragraph>
          <Base.Gap />
        </Base.Text>
        <Base.Heading>Editing</Base.Heading>
        <Base.Text>
          <Header level={3}>Step 1</Header>
          <Base.Image src={Step1EditImage} />
          <Paragraph>
            Select your ground station to open up the details, if they do not
            appear, ensure your ground station is selected and click the blue
            ‘info’ button.
          </Paragraph>
          <Base.Gap />
          <Header level={3}>Step 2</Header>
          <Base.Image src={Step2EditImage} />
          <Paragraph>
            Navigate to the settings tab, from there select ‘Edit Ground
            Station’ which will bring up a dialog box on the right
          </Paragraph>
          <Base.Gap />
          <Header level={3}>Step 3</Header>
          <Base.Image src={Step3EditImage} />
          <div style={{ margin: '1rem 0' }}>
            <Alert info>
              The map on the bottom right corner of your screen can provide
              approximate GPS coordinates.
            </Alert>
          </div>
          <Paragraph>
            To change the location of your ground station, edit the latitude and
            the longitude.
          </Paragraph>
          <Base.Gap />
          <Header level={3}>Step 4</Header>
          <Base.Image src={Step4EditImage} />
          <Paragraph>
            You are also able to visualise your field of view by checking the
            ‘FOV enable’ which will allow you to better edit the angle and size
            of the FOV cone.
          </Paragraph>
          <BtnGroup>
            <Link to="/tutorials/tutorial2" style={{ textDecoration: 'none' }}>
              <Button ghost>Prev</Button>
            </Link>
            <Link to="/tutorials/tutorial4" style={{ textDecoration: 'none' }}>
              <Button>Next</Button>
            </Link>
          </BtnGroup>
        </Base.Text>
      </Container.Dark>
    </Layout>
  );
}

export default Step1;
